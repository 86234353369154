.DeviceControl {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em;
}

.DeviceControlButton {
    background: linear-gradient(to top, var(--bg-color-off) 0 var(--brightness), black var(--brightness) 100%);
    min-width: 5em;
    min-height: 5em;
    border-radius: 50%;
}

.DeviceControlButtonOn {
    background: linear-gradient(to top, var(--bg-color-on) 0 var(--brightness), var(--bg-color-off) var(--brightness) 100%);
}

.DeviceControlBrightnessModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,calc(1 - var(--newBrightnessValue) / 200));
}

.DeviceControlBrightnessModalBrightnessText {
    font-size: 4em;
}

.DeviceControlBrightnessModalDeviceName {
    font-size: 2em;
}