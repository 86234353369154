const {assign} = require('lodash');

const HOSTNAME = '192.168.10.200:8090'
const BASE_URL = `http://${HOSTNAME}/graphql`

const QUERY = {
  GET_STRUCTURE: `{
  getVeevEntities(filters: [
    {veevEntityType:VeevHouse},
    {veevEntityType:VeevMode},
    {veevEntityType:VeevDevice}
  ]) {
    ...on VeevHouse {
      id,
      name,
      veevEntityType
    }
    ...on VeevMode {
      id
      name
      veevEntityType
    }
    ...on VeevDevice {
      id
      name
      type
      capabilitiesStates {
        capability {
          name
        }
        state
      }
      veevEntityType
    }
  }
  listRooms {
    id,
    name,
    devices {
      deviceId,
      type {
        id
      }
    }
  }
  getHouseStructure {
    rooms {
      id
      controlPanels {
        id
      }
    }
  }
}`,
  GET_CONTROL_PANEL_CONFIG: `query getControlPanelConfig($configId: String!){
  getVeevControlPanelConfig(id: $configId) {
    mainScreen {
      items {
        entity {
          ...on VeevDevice {
            id
            type
          }
        }
      }
    }
  }
}`
}

const MUTATION = {
  SET_BRIGHTNESS: `mutation SetBrightness($deviceId: String!, $brightness: String!) {
  updateDeviceState(input: {deviceId: $deviceId, states: [{property: "brightness", state: $brightness}]}) {
    status
  }
}`,
  SET_POWER: `mutation TurnOn($deviceId: String!, $powerState: String!) {
  updateDeviceState(input: {deviceId: $deviceId, states: [{property: "powerState", state: $powerState}]}) {
    status
  }
}`,
  ACTIVATE_MODE: `mutation ActivateMode($modeId: String!) {
  activateScene(sceneId: $modeId) {
    status
  }
}`
}

module.exports = {
  request,
  getLayouts,
  QUERY,
  MUTATION,
  HOSTNAME,
}

async function request(query, variables) {
  const response = await fetch(BASE_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables,
    })
  })

  if (response.ok) {
    const {data, errors} = await response.json();
    if (errors?.length) {
      const error = new Error('GraphQL error');
      error.data = {errors};
      throw error;
    }
    return data;
  }

  throw new Error(`${response.statusText}: ${await response.text()}`);
}

/**
 * @typedef House
 * @prop {string} id
 * @prop {string} name
 * @prop {Array<Room>} rooms
 * @prop {Array<Mode>} modes
 */

/**
 * @typedef Room
 * @prop {string} name
 * @prop {Array<Device>} devices
 */

/**
 * @typedef Device
 * @prop {string} id
 * @prop {string} name
 * @prop {{[capability: string]: any}} state
 */

/**
 * @typedef Mode
 * @prop {string} id
 * @prop {string} name
 */

/**
 * Convenience method for listing all modes and devices in all houses
 * @returns {Promise<Array<House>>}
 */
async function getLayouts() {
  const {
    getVeevEntities: veevEntities,
    listRooms: roomsList,
    getHouseStructure: floors
  } = await request(QUERY.GET_STRUCTURE, {});
  const house = {rooms: [], modes: []};
  const layouts = [house];
  const rooms = {};
  const devices = {};
  const usedDevices = new Set();

  for (const entity of veevEntities) {
    const {id, name, veevEntityType} = entity;
    switch (veevEntityType) {
      case "VeevHouse":
        // set the ID and name of the house
        assign(house, {id, name});
        break;
      case "VeevMode":
        // populate modes
        house.modes.push({id, name})
        break;
      case "VeevDevice":
        // ignore devices that are not lights
        if (!isLight(entity.type)) break;

        // set light states
        const state = {};
        for (const {capability: {name}, state: capState} of entity.capabilitiesStates) {
          state[name] = capState;
        }
        devices[id] = {
          id,
          name,
          state,
        }
        break;
    }
  }
  for (const rawRoom of roomsList) {
    const {id, name, devices: rawDevices} = rawRoom;
    const room = {id, name, devices: []};
    rooms[id] = room;
    house.rooms.push(room);

    // put lights in their respective rooms
    for (const {deviceId, type: {id: typeId}} of rawDevices) {
      if (!isLight(typeId)) continue;
      room.devices.push(devices[deviceId]);
      usedDevices.add(deviceId);
    }
  }

  // add devices that are on control panels but not in rooms
  for (const {rooms: floorRooms} of floors) {
    for (const rawRoom of floorRooms) {
      const {id: roomId, controlPanels} = rawRoom;
      for (const {id: configId} of controlPanels) {
        const {
          getVeevControlPanelConfig: {
            mainScreen: { items }
          }
        } = await request(QUERY.GET_CONTROL_PANEL_CONFIG, {configId})
        for (const {entity: {id, type}} of items) {
          if (!isLight(type)) continue;
          if (usedDevices.has(id)) continue;

          rooms[roomId].devices.push(devices[id]);
          usedDevices.add(id);
        }
      }
    }
  }

  return layouts;
}

function isLight(type) {
  return ['LIGHT', 'GROUP_LIGHT'].includes(type)
}
