.ControlGroupContainer {
    display: flex;
    flex-direction: column;
}

.ControlGroupList {
    display: flex;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    list-style: none;
}

.ControlGroupListItem {
    display: flex;
}