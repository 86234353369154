.ModeControl {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em;
}

.ModeControlButton {
    min-width: 5em;
    min-height: 5em;
    border-radius: 50%;
    background-color: var(--bg-color-off);
}